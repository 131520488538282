export const PRE_K_GRADE = 'pk';
export const K_GRADE = 'k';
export const FIRST_GRADE = '1';
export const SECOND_GRADE = '2';
export const THIRD_GRADE = '3';
export const FOURTH_GRADE = '4';
export const FIVE_GRADE = '5';
export const MATH_SUBJECT_CODE = 'math';
export const READING_SUBJECT_CODE = 'ela';

export const AGE_TWO = 2;
export const AGE_THREE = 3; // default age for PreK grade
export const AGE_FOUR = 4;
export const AGE_FIVE = 5; // default age for K grade

export const gradeMappingForCompare = {
    'pk' : 1,
    'k' : 2,
    '1': 3,
    '2': 4,
    '3': 5,
    '4': 6,
    '5': 7
}

export const FREEMIUM_NO_BLOCKER_TIME_IN_MINUTE = 10;

export const ACTIVE_TAB_CODE_KEY = 'activeTabCode';
export const TAB_CODE = 'tab_code';
export const LIVE_CLASS = 'live_class';
export const GAME_ZONE = 'game_zone';
export const HOME = 'home';
export const CURRICULUM = 'curriculum';
export const PRINTABLES = 'printables';
export const BOOKLIBRARY = 'book_library'
export const EARLY_LEARNING_PROGRAM = "ELP";
export const ELEMENTARY_SCHOOL_PROGRAM = "ESP";
export const SHOW_POPUP = "show_popup"
export const UNLIMITED = 'unlimited';

// For grade change popup in case of autologin
export const gradeMappingForDisplay = {
    'pk' : 'Pre K',
    'k' : 'K',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5'
}

export const PARENTPTL_NEW_SIGNUPS_DISCOUNT=65;
export const PARENTPTL_NEW_SIGNUPS_DISCOUNT_CODE="parentsignuppromo45";

export const cityCountryCodeMapping = {
    "Andorra": "AD",
    "Dubai": "AE",
    "Kabul": "AF",
    "Tirane": "AL",
    "Yerevan": "AM",
    "Casey": "AQ",
    "Davis": "AQ",
    "Mawson": "AQ",
    "Palmer": "AQ",
    "Rothera": "AQ",
    "Troll": "AQ",
    "Buenos_Aires": "AR",
    "Cordoba": "AR",
    "Salta": "AR",
    "Jujuy": "AR",
    "Tucuman": "AR",
    "Catamarca": "AR",
    "La_Rioja": "AR",
    "San_Juan": "AR",
    "Mendoza": "AR",
    "San_Luis": "AR",
    "Rio_Gallegos": "AR",
    "Ushuaia": "AR",
    "Pago_Pago": "AS",
    "Vienna": "AT",
    "Lord_Howe": "AU",
    "Macquarie": "AU",
    "Hobart": "AU",
    "Melbourne": "AU",
    "Sydney": "AU",
    "Broken_Hill": "AU",
    "Brisbane": "AU",
    "Lindeman": "AU",
    "Adelaide": "AU",
    "Darwin": "AU",
    "Perth": "AU",
    "Eucla": "AU",
    "Baku": "AZ",
    "Barbados": "BB",
    "Dhaka": "BD",
    "Brussels": "BE",
    "Sofia": "BG",
    "Bermuda": "BM",
    "La_Paz": "BO",
    "Noronha": "BR",
    "Belem": "BR",
    "Fortaleza": "BR",
    "Recife": "BR",
    "Araguaina": "BR",
    "Maceio": "BR",
    "Bahia": "BR",
    "Sao_Paulo": "BR",
    "Campo_Grande": "BR",
    "Cuiaba": "BR",
    "Santarem": "BR",
    "Porto_Velho": "BR",
    "Boa_Vista": "BR",
    "Manaus": "BR",
    "Eirunepe": "BR",
    "Rio_Branco": "BR",
    "Thimphu": "BT",
    "Minsk": "BY",
    "Belize": "BZ",
    "St_Johns": "CA",
    "Halifax": "CA",
    "Glace_Bay": "CA",
    "Moncton": "CA",
    "Goose_Bay": "CA",
    "Toronto": "CA",
    "Iqaluit": "CA",
    "Winnipeg": "CA",
    "Resolute": "CA",
    "Rankin_Inlet": "CA",
    "Regina": "CA",
    "Swift_Current": "CA",
    "Edmonton": "CA",
    "Cambridge_Bay": "CA",
    "Inuvik": "CA",
    "Dawson_Creek": "CA",
    "Fort_Nelson": "CA",
    "Whitehorse": "CA",
    "Dawson": "CA",
    "Vancouver": "CA",
    "Zurich": "CH",
    "Abidjan": "CI",
    "Rarotonga": "CK",
    "Santiago": "CL",
    "Punta_Arenas": "CL",
    "Easter": "CL",
    "Shanghai": "CN",
    "Urumqi": "CN",
    "Bogota": "CO",
    "Costa_Rica": "CR",
    "Havana": "CU",
    "Cape_Verde": "CV",
    "Nicosia": "CY",
    "Famagusta": "CY",
    "Prague": "CZ",
    "Berlin": "DE",
    "Santo_Domingo": "DO",
    "Algiers": "DZ",
    "Guayaquil": "EC",
    "Galapagos": "EC",
    "Tallinn": "EE",
    "Cairo": "EG",
    "El_Aaiun": "EH",
    "Madrid": "ES",
    "Ceuta": "ES",
    "Canary": "ES",
    "Helsinki": "FI",
    "Fiji": "FJ",
    "Stanley": "FK",
    "Kosrae": "FM",
    "Faroe": "FO",
    "Paris": "FR",
    "London": "GB",
    "Tbilisi": "GE",
    "Cayenne": "GF",
    "Gibraltar": "GI",
    "Nuuk": "GL",
    "Danmarkshavn": "GL",
    "Scoresbysund": "GL",
    "Thule": "GL",
    "Athens": "GR",
    "South_Georgia": "GS",
    "Guatemala": "GT",
    "Guam": "GU",
    "Bissau": "GW",
    "Guyana": "GY",
    "Hong_Kong": "HK",
    "Tegucigalpa": "HN",
    "Port-au-Prince": "HT",
    "Budapest": "HU",
    "Jakarta": "ID",
    "Pontianak": "ID",
    "Makassar": "ID",
    "Jayapura": "ID",
    "Dublin": "IE",
    "Jerusalem": "IL",
    "Kolkata": "IN",
    "Calcutta": "IN",
    "Chagos": "IO",
    "Baghdad": "IQ",
    "Tehran": "IR",
    "Rome": "IT",
    "Jamaica": "JM",
    "Amman": "JO",
    "Tokyo": "JP",
    "Nairobi": "KE",
    "Bishkek": "KG",
    "Tarawa": "KI",
    "Kanton": "KI",
    "Kiritimati": "KI",
    "Pyongyang": "KP",
    "Seoul": "KR",
    "Almaty": "KZ",
    "Qyzylorda": "KZ",
    "Qostanay": "KZ",
    "Aqtobe": "KZ",
    "Aqtau": "KZ",
    "Atyrau": "KZ",
    "Oral": "KZ",
    "Beirut": "LB",
    "Colombo": "LK",
    "Monrovia": "LR",
    "Vilnius": "LT",
    "Riga": "LV",
    "Tripoli": "LY",
    "Casablanca": "MA",
    "Chisinau": "MD",
    "Kwajalein": "MH",
    "Yangon": "MM",
    "Ulaanbaatar": "MN",
    "Hovd": "MN",
    "Choibalsan": "MN",
    "Macau": "MO",
    "Martinique": "MQ",
    "Malta": "MT",
    "Mauritius": "MU",
    "Maldives": "MV",
    "Mexico_City": "MX",
    "Cancun": "MX",
    "Merida": "MX",
    "Monterrey": "MX",
    "Matamoros": "MX",
    "Chihuahua": "MX",
    "Ciudad_Juarez": "MX",
    "Ojinaga": "MX",
    "Mazatlan": "MX",
    "Bahia_Banderas": "MX",
    "Hermosillo": "MX",
    "Tijuana": "MX",
    "Kuching": "MY",
    "Maputo": "MZ",
    "Windhoek": "NA",
    "Noumea": "NC",
    "Norfolk": "NF",
    "Lagos": "NG",
    "Managua": "NI",
    "Kathmandu": "NP",
    "Nauru": "NR",
    "Niue": "NU",
    "Auckland": "NZ",
    "Chatham": "NZ",
    "Panama": "PA",
    "Lima": "PE",
    "Tahiti": "PF",
    "Marquesas": "PF",
    "Gambier": "PF",
    "Port_Moresby": "PG",
    "Bougainville": "PG",
    "Manila": "PH",
    "Karachi": "PK",
    "Warsaw": "PL",
    "Miquelon": "PM",
    "Pitcairn": "PN",
    "Puerto_Rico": "PR",
    "Gaza": "PS",
    "Hebron": "PS",
    "Lisbon": "PT",
    "Madeira": "PT",
    "Azores": "PT",
    "Palau": "PW",
    "Asuncion": "PY",
    "Qatar": "QA",
    "Bucharest": "RO",
    "Belgrade": "RS",
    "Kaliningrad": "RU",
    "Moscow": "RU",
    "Simferopol": "RU",
    "Kirov": "RU",
    "Volgograd": "RU",
    "Astrakhan": "RU",
    "Saratov": "RU",
    "Ulyanovsk": "RU",
    "Samara": "RU",
    "Yekaterinburg": "RU",
    "Omsk": "RU",
    "Novosibirsk": "RU",
    "Barnaul": "RU",
    "Tomsk": "RU",
    "Novokuznetsk": "RU",
    "Krasnoyarsk": "RU",
    "Irkutsk": "RU",
    "Chita": "RU",
    "Yakutsk": "RU",
    "Khandyga": "RU",
    "Vladivostok": "RU",
    "Ust-Nera": "RU",
    "Magadan": "RU",
    "Sakhalin": "RU",
    "Srednekolymsk": "RU",
    "Kamchatka": "RU",
    "Anadyr": "RU",
    "Riyadh": "SA",
    "Guadalcanal": "SB",
    "Khartoum": "SD",
    "Singapore": "SG",
    "Paramaribo": "SR",
    "Juba": "SS",
    "Sao_Tome": "ST",
    "El_Salvador": "SV",
    "Damascus": "SY",
    "Grand_Turk": "TC",
    "Ndjamena": "TD",
    "Bangkok": "TH",
    "Dushanbe": "TJ",
    "Fakaofo": "TK",
    "Dili": "TL",
    "Ashgabat": "TM",
    "Tunis": "TN",
    "Tongatapu": "TO",
    "Istanbul": "TR",
    "Taipei": "TW",
    "Kyiv": "UA",
    "New_York": "US",
    "Detroit": "US",
    "Louisville": "US",
    "Monticello": "US",
    "Indianapolis": "US",
    "Vincennes": "US",
    "Winamac": "US",
    "Marengo": "US",
    "Petersburg": "US",
    "Vevay": "US",
    "Chicago": "US",
    "Tell_City": "US",
    "Knox": "US",
    "Menominee": "US",
    "Center": "US",
    "New_Salem": "US",
    "Beulah": "US",
    "Denver": "US",
    "Boise": "US",
    "Phoenix": "US",
    "Los_Angeles": "US",
    "Anchorage": "US",
    "Juneau": "US",
    "Sitka": "US",
    "Metlakatla": "US",
    "Yakutat": "US",
    "Nome": "US",
    "Adak": "US",
    "Honolulu": "US",
    "Montevideo": "UY",
    "Samarkand": "UZ",
    "Tashkent": "UZ",
    "Caracas": "VE",
    "Ho_Chi_Minh": "VN",
    "Efate": "VU",
    "Apia": "WS",
    "Johannesburg": "ZA",
    "Antigua": "AG",
    "Anguilla": "AI",
    "Luanda": "AO",
    "McMurdo": "AQ",
    "DumontDUrville": "AQ",
    "Syowa": "AQ",
    "Vostok": "AQ",
    "Aruba": "AW",
    "Mariehamn": "AX",
    "Sarajevo": "BA",
    "Ouagadougou": "BF",
    "Bahrain": "BH",
    "Bujumbura": "BI",
    "Porto-Novo": "BJ",
    "St_Barthelemy": "BL",
    "Brunei": "BN",
    "Kralendijk": "BQ",
    "Nassau": "BS",
    "Gaborone": "BW",
    "Blanc-Sablon": "CA",
    "Atikokan": "CA",
    "Creston": "CA",
    "Cocos": "CC",
    "Kinshasa": "CD",
    "Lubumbashi": "CD",
    "Bangui": "CF",
    "Brazzaville": "CG",
    "Douala": "CM",
    "Curacao": "CW",
    "Christmas": "CX",
    "Busingen": "DE",
    "Djibouti": "DJ",
    "Copenhagen": "DK",
    "Dominica": "DM",
    "Asmara": "ER",
    "Addis_Ababa": "ET",
    "Chuuk": "FM",
    "Pohnpei": "FM",
    "Libreville": "GA",
    "Grenada": "GD",
    "Guernsey": "GG",
    "Accra": "GH",
    "Banjul": "GM",
    "Conakry": "GN",
    "Guadeloupe": "GP",
    "Malabo": "GQ",
    "Zagreb": "HR",
    "Isle_of_Man": "IM",
    "Reykjavik": "IS",
    "Jersey": "JE",
    "Phnom_Penh": "KH",
    "Comoro": "KM",
    "St_Kitts": "KN",
    "Kuwait": "KW",
    "Cayman": "KY",
    "Vientiane": "LA",
    "St_Lucia": "LC",
    "Vaduz": "LI",
    "Maseru": "LS",
    "Luxembourg": "LU",
    "Monaco": "MC",
    "Podgorica": "ME",
    "Marigot": "MF",
    "Antananarivo": "MG",
    "Majuro": "MH",
    "Skopje": "MK",
    "Bamako": "ML",
    "Saipan": "MP",
    "Nouakchott": "MR",
    "Montserrat": "MS",
    "Blantyre": "MW",
    "Kuala_Lumpur": "MY",
    "Niamey": "NE",
    "Amsterdam": "NL",
    "Oslo": "NO",
    "Muscat": "OM",
    "Reunion": "RE",
    "Kigali": "RW",
    "Mahe": "SC",
    "Stockholm": "SE",
    "St_Helena": "SH",
    "Ljubljana": "SI",
    "Longyearbyen": "SJ",
    "Bratislava": "SK",
    "Freetown": "SL",
    "San_Marino": "SM",
    "Dakar": "SN",
    "Mogadishu": "SO",
    "Lower_Princes": "SX",
    "Mbabane": "SZ",
    "Kerguelen": "TF",
    "Lome": "TG",
    "Port_of_Spain": "TT",
    "Funafuti": "TV",
    "Dar_es_Salaam": "TZ",
    "Kampala": "UG",
    "Midway": "UM",
    "Wake": "UM",
    "Vatican": "VA",
    "St_Vincent": "VC",
    "Tortola": "VG",
    "St_Thomas": "VI",
    "Wallis": "WF",
    "Aden": "YE",
    "Mayotte": "YT",
    "Lusaka": "ZM",
    "Harare": "ZW"
};

export const FREE_WS_DOWNLOAD_COUNT = 2;
export const PARENT = 'parent';

export const CLASSROOM_LINKED = 'classroom_linked';

export const SHOW_DELETE_ICON_B2B = false;

export const SUMMER_LEARNING_ENABLED = false;

export const TEACHER_AB_TEST = 'teachers-ab-test';

export const EXPIRED = "expired";

export const TEACHER_DISCOUNT_PERCENTAGE = "37%";

export const gpayKey = 'is-gpay-available';

export const QuestionState =  {
    "none" : "NONE",
    "answered" : "ANSWERED",
    "unAnswered" : "UNANSWERED",
}

export const WORKSHEET = 'Worksheet'
export const HOME_SCHOOL_POPUP_SHOWN_KEY = 'home_school_popup_shown';
export const IS_PREMIUM_LIVE_PURCHASED = 'is_premium_live_purchased';
export const ZOHO_PAGESENSE_SCRIPT = 'https://cdn.pagesense.io/js/splashlearn/0763448b82a04ecabdf1f12f3b7a2db1.js'

export const OLD_LP_LIVE_CLASS = "old_lp_live_class";
export const OLD_LP_LIVE_CLASS_PTL = "old_lp_live_class_ptl";

export const OldPurchasePagePlanCodes = [OLD_LP_LIVE_CLASS, OLD_LP_LIVE_CLASS_PTL];

export const LIVE_CLASS_SURVEY_URL = 'live_class_survey_url';
export const SURVEY_URL_PARAM_NAME = 'survey_url';
export const ENDED_FOR_ALL = 'ended_for_all';


export const GOLD_EVENT_PROPERTIES = [
  "Playable Code", 
  "Game Code",
  "Game Engine",
  "Content Type",
  "Playable Click Id",
  "Recommended Rail Name",
  "Recommended Rail Rank",
  "Recommended Tile Name",
  "Recommended Tile Rank",
];

export const PURCHASE_CLICK_ID = 'purchase_click_id';

export const REFERRER_PAGE_MAP = {
    "1": "roster",
    "2": "home_access",
    "3": "custom_math_fact_assignment_pop_up",
    "4": "adaptive_math_fact_assignment_pop_up",
    "5": "curriculum_assignment_pop_up",
    "6": "download_letter_reminder_email",
    "7": "teacher_dashboard_nudge",
    "8": "springboard_enrollment",
    "9": "assignment_page_nudge",
    "10": "default",
    "11": "springboard_certificate",
    "12": "teacher_onboarding_page",
    "14": "summer_learning",
    "15": "summer_learning_bundle",
    "16": "live_klass",
    "17": "topic_assignment_pop_up",
    "18": "assignment_page_share_icon",
    "19": "book_library"
  };

export const AGE_GATE = {
    'heading': 'Parents Only',
    'subheading': 'Please enter your year of birth'
};
  